import start_full from "../../../statics/images/icons/start_full.svg";
import start_half from "../../../statics/images/icons/start_half.svg";
import g2_icon from  "../../../statics/images/icons/g2_icon.svg";
import puffy_image from "../../../statics/images/companies/puffy.svg";
import dimofoundation_image from "../../../statics/images/companies/dimofoundation.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export function HomeSection003() {
    return <div className={"d-flex flex-column my-4 py-5"}>
        <div className={"d-flex flex-column"}>
            <h2 className={"souvenir-font text-center"}>Trusted by Fastest-Growing Brands</h2>
            <p className={"d-flex justify-content-center align-items-center big p-3"}>
                <img src={g2_icon} alt={"star_full"}/>
                <span className={"fw-bold px-2"}>
                    G2 ratings
                </span>
                <div className={"px-1"}>
                    {
                        Array.from({length: 4}).map(i => {
                            return <img key={'start_' + i} src={start_full} alt={"star_full"}/>
                        })
                    }
                    <img src={start_half} alt={"star_full"}/>
                </div>
                4.9 stars
            </p>
        </div>
        <div className={"d-flex py-4 flex-wrap"}>
            <div className={"d-flex flex-column border-start border-2 p-3 col-md-4 col-sm-12"}>
                <div className={"flex-fill"}>
                    <h1 className={"souvenir-font big d-inline"}>65%+</h1> AI resolved inquiries <br/> with
                    <h1 className={"souvenir-font big d-inline"}>90%</h1> CSAT
                </div>

                <div>
                    <div className={"py-3"}>
                        <img src={puffy_image} alt={"puffy"}/>
                    </div>
                    <a href={"#read"} className={"text-decoration-none fw-bold"}>
                        <p>
                            Read success story <FontAwesomeIcon icon={"chevron-right"} size={"sm"}/>
                        </p>
                    </a>
                </div>
            </div>
            <div className={"d-flex flex-column border-start border-2 p-3 col-md-4 col-sm-12"}>
                <div className={"flex-fill"}>
                    <h1 className={"souvenir-font big d-inline"}>2.6x</h1> conversion <br/>
                    <h1 className={"souvenir-font big d-inline"}>1.3x</h1> AOV
                </div>
                <div>
                    <div className={"pt-3"}>
                        <p className={"m-0"}>Achieved by a</p>
                    </div>
                    <a href={"#read"} className={"text-decoration-none fw-bold"}>
                        <p>
                            Premium skincare brand <FontAwesomeIcon icon={"chevron-right"} size={"sm"} />
                        </p>
                    </a>
                </div>
            </div>
            <div className={"d-flex flex-column border-start border-2 p-3 col-md-4 col-sm-12"}>
                <div className={"flex-fill"}>
                    <h1 className={"souvenir-font big d-inline"}>50%</h1> <br/>
                    Faster resolution time
                </div>
                <div>
                    <div className={"py-3"}>
                        <img src={dimofoundation_image} alt={"puffy"}/>
                    </div>
                    <a href={"#read"} className={"text-decoration-none fw-bold"}>
                        <p>
                            Read success story <FontAwesomeIcon icon={"chevron-right"} size={"sm"} />
                        </p>
                    </a>
                </div>
            </div>
        </div>
    </div>
}
