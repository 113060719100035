import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './tests/reportWebVitals';

import 'bootstrap/dist/css/bootstrap.min.css';
// import 'react-circular-progressbar/dist/styles.css';

import './index.css';
import App from './main/gleen/components/app/App';

import { BrowserRouter } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {faChevronDown, faChevronRight, faEnvelope, faInfoCircle} from "@fortawesome/free-solid-svg-icons";

library.add(
    fab,
    faEnvelope,
    faInfoCircle,
    faChevronDown,
    faChevronRight
);


const app = (<React.StrictMode>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
</React.StrictMode>);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(app);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
