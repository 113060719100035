import React from "react";
import {Button} from "react-bootstrap";
import line_grow_image from "../../../statics/images/icons/line_grow.svg";

export function FooterTopComponent() {
    return <div className={"d-flex flex-column p-5 bg-gleen-secondary text-white"}>
        <div className={"text-center"}>
            <img src={line_grow_image} alt={"grow"} />
        </div>
        <h2 className={"souvenir-font text-center"}> Ready to Elevate Your eCommerce Experience?</h2>
        <div className={"d-flex justify-content-center"}>
            <div className={"px-1"}>
                <Button>Start for free</Button>
            </div>
            <div className={"px-1"}>
                <Button variant={"outline-light"}>Get a demo</Button>
            </div>
        </div>
    </div>
}
