import React from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom";
import {HOME_URL} from "../../constants/application/ApplicationUrl";
import {ScreenSwitcherComponent} from "../switcher/ScreenSwitcher";

function App() {
  return (
    <div className="App d-flex flex-column">
        <Routes>
            <Route path={HOME_URL + "/*"} element={<ScreenSwitcherComponent />} />
        </Routes>
    </div>
  );
}

export default App;
