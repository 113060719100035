import {Button, FormControl} from "react-bootstrap";
import puffy_image from "../../../statics/images/companies/puffy.svg";
import butchercrowd_image from "../../../statics/images/companies/butchercrowd.svg";
import tatcha_image from "../../../statics/images/companies/tatcha.svg";
import crocus_image from "../../../statics/images/companies/crocus.svg";
import unilever_image from "../../../statics/images/companies/unilever.svg";
import fairybell_image from "../../../statics/images/companies/fairybell.svg";
import {ImagePropsInterface} from "../../../interfaces/ImagePropsInterface";

export function HomeSection001() {
    return <div>
        <div className={"text-center pt-5"}>
            <h1 className={"py-4 souvenir-font big"}>
                Shopping & Support Concierge <br/> for Your Online Store.
            </h1>
            <p className={"big"}>
                Personalized AI assistance at scale — <br/>
                without compromising your brand’s quality.
            </p>
        </div>
        <EnterWorkEmailComponent/>
        <ImageLineComponent/>
    </div>
}

function OnSubmitWorkEmail(event: any) {
    event.preventDefault();
    return false;
}

function EnterWorkEmailComponent() {
    return <div className={"py-3"}>
        <form className={"d-flex justify-content-center"} onSubmit={OnSubmitWorkEmail}>
                <div className={"p-2 min-w-30p"}>
                    <FormControl type={"email"} required={true} className={"shadow-none"} name={"work_company"}
                                 placeholder={"Enter your work email"}/>
                </div>
                <div className={"p-2"}>
                    <Button type={"submit"} variant={"primary"}> Start for free </Button>
                </div>
        </form>
    </div>
}

// "" - Alwys display
// "x" - hides on screen of size x (x = sm, md, lg, xl)
function ImageLineComponent() {
    return <div className={"d-flex justify-content-center flex-nowrap overflow-hidden"}>
        <ImageComponent imageName={puffy_image} altName={"puffy"} hide={""}/>
        <ImageComponent imageName={butchercrowd_image} altName={"butchercrowd"} hide={"md"} />
        <ImageComponent imageName={tatcha_image} altName={"tatcha"} hide={"sm"} />
        <ImageComponent imageName={crocus_image} altName={"crocus"} hide={"lg"} />
        <ImageComponent imageName={unilever_image} altName={"unilever"} hide={""}/>
        <ImageComponent imageName={fairybell_image} altName={"fairybell"} hide={"xl"}/>
    </div>
}

function ImageComponent({imageName, altName, hide}: ImagePropsInterface) {
    return <div className={`p-3 flex-shrink-0  d-${hide?'none':'block'} d-${hide}-block`}>
        <img src={imageName} alt={altName} className={"h-30px"}/>
    </div>
}
