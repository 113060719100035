import {Route, Routes} from "react-router-dom";
import {HOME_URL} from "../../constants/application/ApplicationUrl";
import React from "react";
import {GleenHomePage} from "../screens/home/GleenHomePage";
import {FooterComponent} from "./footer/FooterComponent";
import {NavbarComponent} from "./header/NavbarComponent";

export function ScreenSwitcherComponent() {
    return <section className={"d-flex flex-fill flex-column"}>
        <NavbarComponent />
        <div className={"d-flex flex-fill"}>
            <Routes>
                <Route path={HOME_URL + "/*"} element={<GleenHomePage />} />
            </Routes>
        </div>
        <FooterComponent />
    </section>
}
