import {Button, Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import gleen_icon from "../../../statics/images/icons/gleen_icon.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export function NavbarComponent() {
    return <Navbar collapseOnSelect expand="xl" fixed={"top"} sticky={"top"} className="bg-white">
            <Container>
                <Navbar.Brand href="#home">
                    <img src={gleen_icon} alt={"gleen"} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="flex-grow-1 justify-content-center">
                        <NavDropdown title={DropDownTitle("Products")}>
                            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title={DropDownTitle("Solutions")}>
                            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title={DropDownTitle("Pricing")}>
                            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title={DropDownTitle("Integrations")}>
                            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title={DropDownTitle("Resources")}>
                            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title={DropDownTitle("Company")}>
                            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                    <Nav>
                        <Nav.Link href="#deets" className={"fw-bold d-flex align-items-center"}>
                            Sign-in
                        </Nav.Link>
                        <Nav.Link eventKey={2} href="#memes">
                            <Button className={"btn-navbar"} variant={"outline-primary"}>Book a demo</Button>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
}

function DropDownTitle(title: string) {
    return <div>
        {title} <FontAwesomeIcon size={"xs"} icon={"chevron-down"}/>
    </div>
}
