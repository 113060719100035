import Lottie from "lottie-react";
import React from "react";

const feature_images = [{
    image: require("../../../statics/images/allinone/hallucinationFree.json")
}, {
    image: require("../../../statics/images/allinone/247_available.json")
}, {
    image: require("../../../statics/images/allinone/seamless_integration.json")
}];

const features = [{
    title: "Hallucination-Free, Fact-Based AI",
    content: "100% accurate answers you can trust, while ensuring your brand’s tone and style stay consistent"
},{
    title: "24/7 Availability Across All Channels",
    content: "Always available on chat, email, SMS, and messaging apps —your customers never wait for help."
},{
    title: "Seamless Integration with Your Tools",
    content: "Effortlessly connects to your existing eCommerce stack, without the technical headaches."
}]

export function HomeSection005() {
    return <div className={"d-flex flex-column py-4"}>
        <h2 className={"text-center souvenir-font py-4 mb-5"}>Your All-in-One Shopping & Support AI</h2>
        {
            feature_images.map((image_resource, index) => <AllInOneComponent image_resource={image_resource.image} image_index={index} />)
        }
    </div>
}

function AllInOneComponent({image_resource, image_index}: {image_resource: string, image_index: number}) {
    return <div className={"d-flex flex-wrap mb-5 justify-content-between"}>
        <div className={"col-md-6"}>
            <div className={"col-md-9 my-5 px-1"}>
                {
                    features.map((feature, index) => <TitleSubjectSection title={feature.title}
                                                                          content={feature.content} is_active={index === image_index}/>)
                }
            </div>
        </div>
        <div className={"col-md-4 col-sm-12 d-flex align-items-center"}>
            <div className={"rounded-4 overflow-hidden"}>
                <Lottie animationData={image_resource} autoplay loop/>
            </div>
        </div>
    </div>
}

function TitleSubjectSection({title, content, is_active}: { title: string, content: string, is_active: boolean }) {
    return <div className={"d-flex"} style={{marginBottom: '-0.5rem'}}>
        <div className={`rounded ${is_active && 'z-3'}`} style={{width: '0.5rem', background: '#D7ECFF'}}>
            {is_active &&
                <div className={"rounded w-100 h-100"} style={{background: '#50AFFF'}} />
            }
        </div>
        <div className={`p-3 ${is_active || 'text-disabled'}`}>
            <p className={"fw-bold big m-0"}>
                {title}
            </p>
            <p>
                {content}
            </p>
        </div>
    </div>
}