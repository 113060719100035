import {HomeSection001} from "./HomeSection001";
import {HomeSection002} from "./HomeSection002";
import {HomeSection003} from "./HomeSection003";
import {HomeSection004} from "./HomeSection004";
import {HomeSection005} from "./HomeSection005";
import {FooterTopComponent} from "./FooterTopComponent";

export function GleenHomePage() {
    return <section className={"flex-fill d-flex flex-column"}>
        <div className={"container"}>
            <HomeSection001 />
            <HomeSection002 />
            <HomeSection003 />
            <HomeSection004 />
            <HomeSection005 />
        </div>
        <FooterTopComponent />
    </section>
}
