import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {TitleWithSubjectInterface} from "../../../interfaces/TitleWithSubjectInterface";
import Lottie from "lottie-react";
import React from "react";

const effortless_supports = [{
    title: "Boost Revenue with Smart Shopping Guidance",
    content: "Instantly recommend products to turn browsers into buyers, driving conversions with personalized suggestions.",
    image_resource: require("../../../statics/images/effortlesssupport/boost_revenue.json"),
},{
    title: "Reduce Costs with AI-Powered Automation",
    content: "Automate returns, order tracking, and more complex questions—reducing agent workload and speeding up customer service.",
    image_resource: require("../../../statics/images/effortlesssupport/reduce_costs.json"),
},{
    title: "Gain Actionable Insights",
    content: "Use AI-driven analytics to refine your product offerings, optimize your knowledge base, and improve support accuracy.",
    image_resource: require("../../../statics/images/effortlesssupport/gain_actionable_insights.json"),
}]

export function HomeSection004() {
    return <div className={"d-flex flex-column"}>
        <h2 className={"text-center souvenir-font"}>Effortless Support at Every Step</h2>
        {
            effortless_supports.map((effortless_support, index) => <div className={"d-flex flex-wrap justify-content-between my-5"}>
                {index % 2 === 0 && <TitleWithSubjectComponent title={effortless_support.title}
                                           content={effortless_support.content}/>}
                <div className={"col-md-6"}>
                    <Lottie animationData={effortless_support.image_resource} autoplay loop />
                </div>

                {index % 2 === 1 && <TitleWithSubjectComponent title={effortless_support.title}
                                                               content={effortless_support.content}/>}
            </div>)
        }
    </div>
}

function TitleWithSubjectComponent({title, content, url}: TitleWithSubjectInterface) {
    return <div className={"col-md-4 py-5 align-items-center d-flex align-items-center"}>
        <div className={"col-md-12"}>
            <h2 className={"fw-bold"}>
                {title}
            </h2>
            <p className={"m-0 my-4"}>
                {content}
            </p>
            <div className={"fw-bold"}>
                Learn more {' '}
                <FontAwesomeIcon icon={"chevron-right"} size={"xs"}/>
            </div>
        </div>
    </div>
}